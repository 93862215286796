import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Field, reduxForm } from 'redux-form';
import {
    Button,
    Input,
    Textarea,
    ReCaptcha,
} from 'react-rainbow-components/components';
import sendMessage from '../../../redux/actions/sendMessage';
import User from '../../../components/Icons/User';
import Email from '../../../components/Icons/Email';
import Phone from '../../../components/Icons/Phone';
import ArrowRight from '../../../components/Icons/ArrowRight';
import validate from './validate';

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

function ContactUsForm(props) {
    const {
        isLoading,
        sendMessage,
        handleSubmit,
        reset,
    } = props;

    const submit = (values) => {
        sendMessage(values);
        reset();
        window.grecaptcha.reset();
    };

    return (
        <form
            noValidate
            onSubmit={handleSubmit(submit)}>

            <div className="nexxtway-contactus_input-container">
                <Field
                    component={Input}
                    name="firstname"
                    label="First Name"
                    placeholder="Enter your first name"
                    className="nexxtway-contactus_input"
                    required
                    icon={<User />} />
                <Field
                    component={Input}
                    name="lastname"
                    label="Last Name"
                    placeholder="Enter your last name"
                    className="nexxtway-contactus_input"
                    required
                    icon={<User />} />
            </div>

            <div className="nexxtway-contactus_input-container">
                <Field
                    component={Input}
                    name="email"
                    type="email"
                    label="Email"
                    placeholder="Enter your email"
                    className="nexxtway-contactus_input"
                    required
                    icon={<Email />} />

                <Field
                    component={Input}
                    name="phone"
                    type="tel"
                    pattern="\d*"
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    className="nexxtway-contactus_input"
                    icon={<Phone />} />

            </div>
            <div className="nexxtway-contactus_input-container">
                <Field
                    component={Input}
                    name="company"
                    label="Company Name"
                    placeholder="Enter your company name"
                    className="nexxtway-contactus_input" />

                <Field
                    component={Input}
                    name="jobtitle"
                    label="Job Title"
                    placeholder="Enter your job title"
                    className="nexxtway-contactus_input" />

            </div>
            <Field
                component={Textarea}
                name="message"
                label="Message"
                rows={4}
                placeholder="What else should we know?"
                className="rainbow-m-bottom_large"
                required />

            <div className="nexxtway-contactus_footer-container">
                <Field
                    component={ReCaptcha}
                    name="recaptcha"
                    className="nexxtway-contactus_recaptcha"
                    siteKey={RECAPTCHA_KEY} />

                <Button
                    isLoading={isLoading}
                    variant="brand"
                    type="submit"
                    className="nexxtway-contactus_button">

                    Send
                    <ArrowRight className="nexxtway-contactus_button-icon" />
                </Button>
            </div>
        </form>
    );
}

ContactUsForm.propTypes = {
    isLoading: PropTypes.bool,
    sendMessage: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    reset: PropTypes.func,
};

ContactUsForm.defaultProps = {
    isLoading: false,
    handleSubmit: () => {},
    reset: () => {},
};

function stateToProps(state) {
    return state.contactUs;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        sendMessage,
    }, dispatch);
}

const ReduxFormContactUs = reduxForm({
    form: 'contact-us-form',
    validate,
    touchOnBlur: false,
})(ContactUsForm);

export default connect(stateToProps, dispatchToProps)(ReduxFormContactUs);
