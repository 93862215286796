import React from 'react';
import { QueryAt } from 'react-prismic-cms';
import PrismicSolutions from './prismicSolutions';
import './styles.css';
import './media-queries.css';

export default function Solutions() {
    return (
        <QueryAt
            path="document.type"
            value="solutions"
            component={PrismicSolutions} />
    );
}
