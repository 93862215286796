import React from 'react';
import { QueryAt } from 'react-prismic-cms';
import PrismicServices from './prismicServices';
import './styles.css';
import './media-queries.css';

export default function Services() {
    return (
        <QueryAt
            path="document.type"
            value="services"
            component={PrismicServices} />
    );
}
