import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import './styles.css';
import './media-queries.css';

export default function About() {
    return (
        <ScrollableAnchor id="about">
            <section className="nexxtway-about_container">
                <h2 className="nexxtway-about_header">
                    WHO WE ARE
                </h2>
                <article className="nexxtway-about_content">
                    <img src="assets/images/our-team.svg" alt="working team" className="nexxtway-about_image" />
                    <article className="nexxtway-about_text">
                        <h1 className="nexxtway-about_title">
                            Turning your Ideas into Successful Solutions
                        </h1>
                        <p className="nexxtway-about_description">
                            We’re an organization that provides cutting edge engineering solutions,
                            helping small business and enterprises to reach their business goals and
                            untangle complex issues that always emerge during their digital
                            evolution journey.
                        </p>
                        <p className="nexxtway-about_description">
                            We create websites and apps solutions that users love,
                            turning your Great Ideas into Successful Solutions.
                        </p>
                        <p className="nexxtway-about_description">
                            We meet deadlines and guarantee the quality and efficiency
                            of our services.
                            Even though our methods evolve in response to new, complex challenges,
                            we’re always creating solutions for people first.
                        </p>
                        <p className="nexxtway-about_description">
                            We’re building to learn, and learning as we build, through inspiration,
                            analysis, design, and implementation.
                        </p>
                    </article>
                </article>
            </section>
        </ScrollableAnchor>
    );
}
