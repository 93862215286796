import React from 'react';
import PropTypes from 'prop-types';
import ScrollableAnchor from 'react-scrollable-anchor';
import OfferItem from '../../../components/OfferItem';

function SolutionItems({ results }) {
    return results.map(({ data, id }) => (
        <OfferItem
            key={id}
            className="nexxtway-solutions_item"
            imgSrc={data.image.url}
            title={data.title[0].text}
            href={data.href.url}
            description={data.description[0].text} />
    ));
}

export default function PrismicSolutions({ response }) {
    const hasValidResponse = response && Array.isArray(response.results) && response.results.length;
    if (hasValidResponse) {
        return (
            <ScrollableAnchor id="solutions">
                <section className="nexxtway-solutions_container">
                    <h2 className="nexxtway-solutions_header">
                        OUR SOLUTIONS
                    </h2>
                    <article className="nexxtway-solutions_content">
                        <SolutionItems results={response.results} />
                    </article>
                </section>
            </ScrollableAnchor>
        );
    }
    return null;
}

PrismicSolutions.propTypes = {
    response: PropTypes.object,
};

PrismicSolutions.defaultProps = {
    response: null,
};
