import React from 'react';
import PropTypes from 'prop-types';

const SendEmail = (props) => {
    const { className, style } = props;
    return (
        <svg fill="#ffffff" className={className} style={style} width="16px" height="16px" viewBox="0 0 16 16">
            <path d="M7.57953125,16.6666406 L2.4203125,16.6666406 C1.95476562,16.6666406 1.578125,17.039375 1.578125,17.5 C1.578125,17.960625 1.95476562,18.3333594 2.4203125,18.3333594 C2.9140625,18.3333594 3.284375,18.3333594 3.53125,18.3333594 C4.7815625,18.3333594 6.13098958,18.3333594 7.57953125,18.3333594 C8.045,18.3333594 8.42171875,17.960625 8.42171875,17.5 C8.42171875,17.039375 8.04507813,16.6666406 7.57953125,16.6666406 Z" id="Path" />
            <path d="M9.59359375,11.6666406 L4.9359375,11.6666406 C4.47039063,11.6666406 4.09375,12.039375 4.09375,12.5 C4.09375,12.960625 4.47039063,13.3333594 4.9359375,13.3333594 L9.59359375,13.3333594 C10.0590625,13.3333594 10.4357813,12.960625 10.4357813,12.5 C10.4357813,12.039375 10.0590625,11.6666406 9.59359375,11.6666406 Z" id="Path" />
            <path d="M10.4357812,6.66664062 L0.8421875,6.66664062 C0.376640625,6.66664062 9.78207858e-18,7.039375 9.78207858e-18,7.5 C9.78207858e-18,7.960625 0.376640625,8.33335937 0.8421875,8.33335937 L10.4357812,8.33335937 C10.90125,8.33335937 11.2779687,7.960625 11.2779687,7.5 C11.2779687,7.039375 10.90125,6.66664062 10.4357812,6.66664062 Z" id="Path" />
            <path d="M16.91,3.45375 C20.260625,6.708125 24.059375,10.3978906 25.194375,11.4550781 C25.3514844,11.6007813 25.5636719,11.6764063 25.7775,11.6764063 C25.8778125,11.6764063 25.9782031,11.6601563 26.0735937,11.6259375 C26.1467969,11.5999219 26.3425,11.5307031 26.5728125,11.3744531 C28.0523437,10.3710156 32.8817187,7.20859375 37.396875,4.25203125 L40.5467969,2.1890625 C40.7573437,2.05070313 40.8946875,1.8253125 40.919375,1.57625 C40.9440625,1.3271875 40.8552344,1.080625 40.6759375,0.903203125 C40.0844531,0.320625 39.2999219,0 38.4659375,0 L17.5391406,0 C16.7882812,0 16.0472656,0.2571875 15.4510156,0.7234375 C15.261875,0.87234375 15.1458594,1.09375 15.1327344,1.33296875 C15.1203906,1.57226562 15.2117187,1.805 15.384375,1.97179687 L16.91,3.45375 Z" id="Path" />
            <path d="M40.8032812,4.64109375 C40.5203906,4.45148437 40.1502344,4.44820312 39.8657031,4.63460937 L37.983125,5.8675 C33.5782031,8.75164063 28.9642969,11.7732812 27.5242187,12.7489844 C26.3613281,13.5407812 24.9327344,13.5009375 24.0395312,12.6675781 C22.8922656,11.5998438 19.086875,7.90359375 15.6852344,4.59953125 L15.2427344,4.16984375 C15.023125,3.95578125 14.7048437,3.87765625 14.4088281,3.96640625 C14.114375,4.05429687 13.8914844,4.294375 13.8282031,4.5921875 L11.3453125,16.2628906 C11.3009375,16.4728906 11.2778906,16.6877344 11.2778906,16.9025781 C11.2778906,18.6107812 12.6817969,19.9999219 14.4080469,19.9999219 L35.3348437,19.9999219 C36.9180469,19.9999219 38.3046094,18.8874219 38.6319531,17.3534375 L41.1535156,5.50203125 C41.224375,5.17171875 41.0853906,4.8315625 40.8032812,4.64109375 Z" id="Path" />
            <path d="M5.9203125,4.27085938 L9.87171875,4.27085938 C10.3371875,4.27085938 10.7139063,3.898125 10.7139063,3.4375 C10.7139063,2.976875 10.3372656,2.60414062 9.87171875,2.60414062 L5.9203125,2.60414062 C5.45476563,2.60414062 5.078125,2.976875 5.078125,3.4375 C5.078125,3.898125 5.45476563,4.27085938 5.9203125,4.27085938 Z" id="Path" />
        </svg>
    );
};

SendEmail.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

SendEmail.defaultProps = {
    className: undefined,
    style: undefined,
};

export default SendEmail;
