import React from 'react';
import PropTypes from 'prop-types';

const Menu = (props) => {
    const { className, style } = props;
    return (
        <svg className={className} style={style} fill="#00b0e6" width="22px" height="19px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <path d="M1.65306122,3.30612245 L20.3469388,3.30612245 C21.2598993,3.30612245 22,2.56602173 22,1.65306122 L22,1.65306122 C22,0.74010072 21.2598993,2.76381086e-16 20.3469388,4.4408921e-16 L1.65306122,0 C0.74010072,1.67708124e-16 -1.11805416e-16,0.74010072 0,1.65306122 L0,1.65306122 C1.11805416e-16,2.56602173 0.74010072,3.30612245 1.65306122,3.30612245 Z M1.65306122,10.8061224 L20.3469388,10.8061224 C21.2598993,10.8061224 22,10.0660217 22,9.15306122 L22,9.15306122 C22,8.24010072 21.2598993,7.5 20.3469388,7.5 L1.65306122,7.5 C0.74010072,7.5 -1.11805416e-16,8.24010072 0,9.15306122 L0,9.15306122 C1.11805416e-16,10.0660217 0.74010072,10.8061224 1.65306122,10.8061224 Z M1.65306122,18.3061224 L20.3469388,18.3061224 C21.2598993,18.3061224 22,17.5660217 22,16.6530612 L22,16.6530612 C22,15.7401007 21.2598993,15 20.3469388,15 L1.65306122,15 C0.74010072,15 -1.11805416e-16,15.7401007 0,16.6530612 L0,16.6530612 C1.11805416e-16,17.5660217 0.74010072,18.3061224 1.65306122,18.3061224 Z" />
        </svg>
    );
};

Menu.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Menu.defaultProps = {
    className: undefined,
    style: null,
};

export default Menu;
