import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from 'react-rainbow-components/components/ButtonIcon';
import Modal from 'react-rainbow-components/components/Modal';
import Menu from '../../Icons/Menu';
import './styles.css';

export default class HamburgerMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
        this.toggleModal = this.toggleModal.bind(this);
    }

    toggleModal() {
        const { isOpen } = this.state;
        this.setState({ isOpen: !isOpen });
    }

    render() {
        const { style, className } = this.props;
        const { isOpen } = this.state;
        return (
            <span className={className} style={style}>
                <ButtonIcon
                    size="large"
                    icon={<Menu />}
                    onClick={this.toggleModal}
                />
                <Modal
                    isOpen={isOpen}
                    onRequestClose={this.toggleModal}>
                    <header className="nexxtway-global-header_modal-header">
                        <img src="assets/images/logo.svg" alt="The nexxtway logo" className="nexxtway-global-header_modal-header-logo" />
                    </header>
                    <section className="nexxtway-global-header_modal-link-container">
                        <a
                            href="#about"
                            className="nexxtway-global-header_modal-link"
                            onClick={this.toggleModal}>

                            <h4>About</h4>
                        </a>
                        <a
                            href="#services"
                            className="nexxtway-global-header_modal-link"
                            onClick={this.toggleModal}>

                            <h4>Services</h4>
                        </a>
                        <a
                            href="#solutions"
                            className="nexxtway-global-header_modal-link"
                            onClick={this.toggleModal}>

                            <h4>Solutions</h4>
                        </a>
                        <a
                            href="#contact-us"
                            className="nexxtway-global-header_modal-link"
                            onClick={this.toggleModal}>

                            <h4>Get in Touch</h4>
                        </a>
                    </section>
                </Modal>
            </span>
        );
    }
}

HamburgerMenu.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string,
};

HamburgerMenu.defaultProps = {
    style: undefined,
    className: undefined,
};
