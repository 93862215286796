import React from 'react';
import { Prismic } from 'react-prismic-cms';
import GlobalHeader from '../../components/GlobalHeader/index';
import Footer from '../../components/Footer/index';
import Main from './main/index';
import About from './about/index';
import Services from './services/index';
import Solutions from './solutions/index';
import Contactus from './contactus/index';
import './styles.css';

export default function Home() {
    return (
        <Prismic repo="nexxtway-info">
            <main className="nexxtway-home_viewport">
                <GlobalHeader />
                <Main />
                <About />
                <Services />
                <Solutions />
                <Contactus />
                <Footer />
            </main>
        </Prismic>
    );
}
