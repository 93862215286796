import React from 'react';
import PropTypes from 'prop-types';

const ArrowRight = (props) => {
    const { className, style } = props;
    return (
        <svg fill="#00B0E6" className={className} style={style} width="16px" height="16px" viewBox="0 0 16 16">
            <path d="M7.47789311,0.874271517 C7.75570142,0.603562645 8.20457615,0.603562645 8.47942804,0.874271517 L13.0486661,5.32674186 C13.4804893,5.74752971 13.4894352,6.43870751 13.0686473,6.87053068 C13.0560008,6.88336681 13.0560008,6.88336681 13.0430749,6.89592155 C12.5933638,7.32793529 11.8794925,7.316736 11.4435543,6.8708281 L9.12943932,4.38112264 L9.12943932,13.9790734 C9.12943932,14.6138719 8.6148334,15.1284778 7.98003483,15.1284778 C7.34523626,15.1284778 6.83063034,14.6138719 6.83063034,13.9790734 L6.91638239,4.41607677 L4.51378196,6.87352262 C4.07783244,7.3194238 3.36395963,7.33061575 2.9142463,6.89859983 C2.47932868,6.48079736 2.46545411,5.78953115 2.88325657,5.35461353 C2.89571601,5.34178462 2.89571601,5.34178462 2.90845257,5.32923081 L7.47789311,0.874271517 Z" transform="translate(7.978619, 7.899859) scale(-1, -1) rotate(-90.000000) translate(-7.978619, -7.899859) " />
        </svg>
    );
};

ArrowRight.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

ArrowRight.defaultProps = {
    className: undefined,
    style: undefined,
};

export default ArrowRight;
