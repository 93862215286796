import React, { Component } from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import ButtonLink from '../../../components/ButtonLink';
import './styles.css';
import './media-queries.css';

export default class Main extends Component {
    constructor(props) {
        super(props);
        this.mainRef = React.createRef();
    }

    componentDidMount() {
        const { height } = this.mainRef.current.getBoundingClientRect();
        this.mainRef.current.style.height = height;
    }

    render() {
        return (
            <ScrollableAnchor id="home">
                <div className="nexxtway-main-container">
                    <section ref={this.mainRef} className="nexxtway-main_content-container">
                        <article className="nexxtway-main_content">
                            <h1 className="nexxtway-main_slogan">
                            We believe in the future
                            </h1>
                            <p className="nexxtway-main_description">
                            and the technology that will create it
                            </p>
                            <ButtonLink
                                className="nexxtway-main_button"
                                label="Get in Touch" />

                        </article>
                    </section>
                </div>
            </ScrollableAnchor>
        );
    }
}
