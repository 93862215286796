import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import ButtonIcon from 'react-rainbow-components/components/ButtonIcon';
import { Close } from '../Icons';
import MessageIcon from './icon';
import { hideMessage } from '../../redux/actions/message';
import './styles.css';

function Message(props) {
    const {
        className,
        style,
        message,
        variant,
        hideMessage,
    } = props;

    function getClassName() {
        return classnames('nexxtway-message_container', {
            'nexxtway-message_container--shown': message,
        }, `nexxtway-message_container-${variant}`, className);
    }

    return (
        <article className={getClassName()} style={style}>
            <div className="nexxtway-message_content">
                <MessageIcon variant={variant} />
                <p className="nexxtway-message_text">
                    {message}
                </p>
                <ButtonIcon
                    size="medium"
                    icon={<Close />}
                    onClick={hideMessage}
                    className="nexxtway-message_close-button" />
            </div>
        </article>
    );
}

Message.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    message: PropTypes.node,
    variant: PropTypes.string,
    hideMessage: PropTypes.func.isRequired,
};

Message.defaultProps = {
    className: undefined,
    style: undefined,
    message: undefined,
    variant: 'success',
};

function stateToProps(state) {
    return state.message;
}

function dispatchToProps(dispatch) {
    return bindActionCreators({
        hideMessage,
    }, dispatch);
}

export default connect(stateToProps, dispatchToProps)(Message);
