import React from 'react';
import ScrollableAnchor from 'react-scrollable-anchor';
import Form from './form';
import './styles.css';
import './media-queries.css';

export default function Contactus() {
    return (
        <ScrollableAnchor id="contact-us">
            <section className="nexxtway-contactus_container">
                <h2 className="nexxtway-contactus_header">
                    CONTACT nexxtway
                </h2>
                <article className="nexxtway-contactus_details-container">
                    <p className="nexxtway-contactus_description">
                        <span>Need Forward-Thinking Software?</span>
                        <span>Get in touch with us.</span>
                    </p>
                    {/* <div className="nexxtway-contactus_details-content">
                        <Email />
                        <h3 className="nexxtway-contactus_details">support@nexxtway.com</h3>
                    </div> */}
                </article>
                <article className="nexxtway-contactus_content">
                    <article className="nexxtway-contactus_form">
                        <Form />
                    </article>
                    <img src="assets/images/contact-us.svg" alt="working team" className="nexxtway-contactus_image" />
                </article>
            </section>
        </ScrollableAnchor>
    );
}
