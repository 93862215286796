import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './styles.css';

export default function ButtonLink(props) {
    const {
        className,
        style,
        label,
    } = props;

    const getContainerClassName = () => classnames('nexxtway-button', className);

    return (
        <a href="#contact-us" style={style} className={getContainerClassName()}>
            {label}
        </a>
    );
}

ButtonLink.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    label: PropTypes.string,
};

ButtonLink.defaultProps = {
    className: undefined,
    style: undefined,
    label: undefined,
};
