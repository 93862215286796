import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HamburgerMenu from './HamburgerMenu/index';
import './styles.css';
import './media-queries.css';

export default class GlobalHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opacity: 0,
            currentSection: '#home',
        };
        this.handleScroll = this.handleScroll.bind(this);
        this.setCurrentSection = this.setCurrentSection.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('hashchange', this.setCurrentSection);
    }

    getContainerClassName() {
        const { className } = this.props;
        return classnames('nexxtway-global-header_container', className);
    }

    getLinkClassName(section) {
        const { currentSection } = this.state;
        return classnames('nexxtway-global-header_link', {
            'nexxtway-global-header_link--active': section === currentSection,
        });
    }

    setCurrentSection() {
        const { hash } = window.location;
        const { currentSection } = this.state;
        if (hash && hash !== currentSection) {
            this.setState({ currentSection: hash });
        }
    }

    handleScroll() {
        const scrollPosition = window.scrollY;
        if (scrollPosition <= 190) {
            this.setState({
                opacity: scrollPosition * 0.005,
            });
        } else if (scrollPosition > 190) {
            this.setState({
                opacity: 0.95,
            });
        }
    }

    render() {
        const {
            opacity, fill,
        } = this.state;
        const topBarStyle = {
            backgroundColor: `rgba(255, 255, 255, ${opacity})`,
            borderBottom: `1px solid rgba(248, 249, 252, ${opacity})`,
            fill,
        };

        return (
            <header className={this.getContainerClassName()} style={topBarStyle}>
                <div className="nexxtway-global-header_navigation-container">
                    <a className="nexxtway-global-header_logo-container" href="#home">
                        <img src="assets/images/logo.svg" alt="The nexxtway logo" />
                    </a>
                    <a
                        className={this.getLinkClassName('#about')}
                        href="#about">
                            ABOUT
                    </a>
                    <a
                        className={this.getLinkClassName('#services')}
                        href="#services">
                            SERVICES
                    </a>
                    <a
                        className={this.getLinkClassName('#solutions')}
                        href="#solutions">
                            SOLUTIONS
                    </a>
                    <a
                        className={this.getLinkClassName('#contact-us')}
                        href="#contact-us">
                            GET IN TOUCH
                    </a>
                </div>
                <HamburgerMenu className="nexxtway-global-header_hamburger-menu" />
            </header>
        );
    }
}

GlobalHeader.propTypes = {
    className: PropTypes.string,
};

GlobalHeader.defaultProps = {
    className: undefined,
};
