import React from 'react';
import { Provider } from 'react-redux';
import { configureAnchors } from 'react-scrollable-anchor';
import Application from 'react-rainbow-components/components/Application';
import Message from '../components/Message';
import Home from '../pages/home/index';
import store from '../redux/store';
import './styles.css';

configureAnchors({ offset: -140, scrollDuration: 1200 });

export default function App() {
    return (
        <Provider store={store}>
            <Application>
                <Message />
                <Home />
            </Application>
        </Provider>
    );
}
