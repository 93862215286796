const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export function isEmail(email) {
    return EMAIL_REGEX.test(email);
}

export default function validate(values) {
    const {
        firstname,
        lastname,
        email,
        message,
        recaptcha,
    } = values;
    const errors = {};

    if (!firstname) {
        errors.firstname = 'Looks like you forgot your first name.';
    }
    if (!lastname) {
        errors.lastname = 'Looks like you forgot your last name.';
    }
    if (!email) {
        errors.email = 'Looks like you forgot your email.';
    }
    if (!message) {
        errors.message = 'Looks like you forgot leave a message.';
    }
    if (!recaptcha) {
        errors.recaptcha = 'Are you a robot?';
    }
    if (email && !isEmail(email)) {
        errors.email = 'The email is invalid.';
    }

    return errors;
}
