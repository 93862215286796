import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import RenderIf from 'react-rainbow-components/components/RenderIf';
import ArrowRight from '../Icons/ArrowRight/index';
import './styles.css';

export default function OffertItem(props) {
    const {
        className,
        style,
        imgSrc,
        imgAlt,
        title,
        description,
        href,
    } = props;

    const getContainerClassName = () => classnames('nexxtway-offer-item_container', className);

    return (
        <article style={style} className={getContainerClassName()}>
            <img src={imgSrc} alt={imgAlt} className="nexxtway-offer-item_image" />
            <h4 className="nexxtway-offer-item_title">{title}</h4>
            <p className="nexxtway-offer-item_description">{description}</p>
            <RenderIf isTrue={!!href}>
                <a className="nexxtway-offer-item_link" href={href} target="_blank" rel="noopener noreferrer">
                    View more
                    <ArrowRight className="nexxtway-offer-item_link-icon" />
                </a>
            </RenderIf>
        </article>
    );
}

OffertItem.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
    imgSrc: PropTypes.string,
    imgAlt: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    href: PropTypes.string,
};

OffertItem.defaultProps = {
    className: undefined,
    style: undefined,
    imgSrc: undefined,
    imgAlt: undefined,
    title: undefined,
    description: undefined,
    href: undefined,
};
