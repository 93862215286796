import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import GithubIcon from './github';
import './styles.css';

function getYear() {
    return new Date().getFullYear();
}

export default function Footer(props) {
    const {
        className,
        style,
    } = props;

    const getContainerClassName = () => classnames('nexxtway-footer', className);

    return (
        <footer style={style} className={getContainerClassName()}>
            <p className="nexxtway-footer_copyright">{`© ${getYear()} nexxtway, corp. All rights reserved.`}</p>
            <div className="nexxtway-footer_social-link-container">
                <a href="https://www.linkedin.com/company/nexxtway/about/" target="_blank" rel="noopener noreferrer" className="nexxtway-footer_social-link nexxtway-footer_social-link-linkedin">
                    <img src="assets/images/linkedin.svg" alt="LinkedIn logo" />
                </a>
                <a href="https://github.com/nexxtway" target="_blank" rel="noopener noreferrer">
                    <GithubIcon className="nexxtway-footer_social-link-github" />
                </a>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Footer.defaultProps = {
    className: undefined,
    style: undefined,
};
